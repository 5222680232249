import React from "react";
import { HashRouter as Router, Route, Routes, HashRouter } from "react-router-dom";
import StartYourJourney from "./components/StartYourJourney";
import ApplyToCoach from "./components/ApplyToCoach";
import About from "./components/About";
import Contact from "./components/Contact";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import Profile from "./components/Profile";
import SurfCoaches from "./components/SurfCoaches";
import Footer from "./components/Footer";
import AdminDashboard from "./components/admin/AdminDashboard"; // Import AdminDashboard


function Layout() {
  return (
    <div className="layout">
      <Header />
      <main className="content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/start-your-journey" element={<StartYourJourney />} />
          <Route path="/apply-to-coach" element={<ApplyToCoach />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/adminpage" element={<AdminDashboard />} />
          <Route
            path="/start-your-journey/:findCity/:findState"
            element={<SurfCoaches />}
          />
          <Route
            path="/start-your-journey/:findCity/:findState/:id"
            element={<Profile />}
          />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <HashRouter>
      <Layout />
    </HashRouter>
  );
}

export default App;
