import { useState, useEffect } from "react";
import React from "react";
import { Link } from "react-router-dom";

export default function HomePage() {
  const [homeBackgroundImage, setHomeBackgroundImage] = useState(""); // State to track background image

  useEffect(() => {
    window.scrollTo(0, 0);
    const img = new Image();
    img.src = "../../public/images/background-2.jpg";
    img.onload = () => {
      setHomeBackgroundImage(`url(${img.src})`); // Set the background image once it's loaded
    };
  }, []);
  return (
    <div
      className="home"
      style={{ homeBackgroundImage: homeBackgroundImage }} // Apply background image
    >
      <div className="home-header">
        <div className="home-header-container">
          <h1>Welcome to Get Surfing Lessons</h1>
          <p>
            At Get Surfing Lessons, we connect aspiring surfers with passionate
            surf coaches ready to help you ride the waves.
          </p>
          <Link to="/start-your-journey">
            <button className="find-surf-coaches-button">
              Find Surfing Coaches
            </button>
          </Link>
        </div>
      </div>

      <div className="home-content">
        <div className="surf-coach-container">
          <h2>Why Choose Us?</h2>
          <div className="image-text">
            <div className="surf-lesson-image">
              <img
                src="images/img5.jpg"
                alt="Surfing Lesson"
                className="surf-teaching"
              />
            </div>
            <div className="surf-lesson-text">
              <ul>
                <li>
                  <strong>Find Your Style:</strong> Each coach has a unique
                  teaching style; select a coach that resonates with you!
                </li>
                <li>
                  <strong>Flexible Scheduling:</strong> Choose lesson times that
                  work for you, whether it’s a weekend morning or a weekday
                  afternoon.
                </li>
                <li>
                  <strong>Connect with Local Coaches:</strong> Chat with your
                  coach to find the best surf spot!
                </li>
                <li>
                  <p className="coach-call-to-action">
                    Tired of expensive surf schools? We provide a personalized
                    one on one experience to help you turn surfing into a
                    lifestyle. Sign up now to meet your coach!
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <Link to="/start-your-journey">
            <button className="find-coach-button">
              Find Your Surfing Coach
            </button>
          </Link>
        </div>
      </div>
      <div className="home-bottom">
        <div className="for-aspiring-surfers">
          <h3 className="surfer-heading">Find Your Perfect Surf Coach!</h3>
          <p className="surfer-text-heading">
            Ready to surf? Explore our community of passionate coaches for all
            skill levels, from beginners to advanced surfers.
          </p>

          <p className="surfer-text">
            <strong>Personalized Lessons:</strong> One-on-one sessions tailored
            to your needs and goals.
          </p>
          <p className="surfer-text">
            <strong>Flexible Scheduling:</strong> Lessons that fit your
            availability and lifestyle.
          </p>
          <p className="surfer-text">
            <strong>Learn at Your Pace:</strong> Progress comfortably with
            lessons suited to your style.
          </p>
          <p className="surfer-text">
            <strong>Build Confidence:</strong> Gain skills and knowledge with
            the support of a dedicated coach.
          </p>
          <p className="surfer-text">
            <strong>All Levels Welcome:</strong> Coaches for everyone, from
            first-timers to seasoned surfers.
          </p>

          <p className="surfer-call-to-action">
            Start your surf journey today! Browse our coaches and find your
            match.
          </p>

          <Link to="/start-your-journey">
            <button className="coach-button">Find Your Surf Coach Now!</button>
          </Link>
        </div>
        <div className="for-surf-coaches">
          <h3 className="coach-heading">
            For Surf Coaches: Join Our Community!
          </h3>

          <p className="coach-text-heading">
            Are you a passionate surf coach eager to share your love for the
            ocean? Join our vibrant community of expert coaches and connect with
            students eager to learn!
          </p>

          <p className="coach-text">
            <strong>Free Profile: </strong>Create a free profile showcasing your
            coaching style and experience.
          </p>

          <p className="coach-text">
            <strong>Connect with Students: </strong>Reach out to enthusiastic
            learners, from beginners to advanced surfers.
          </p>

          <p className="coach-text">
            <strong>Earn 100% of Lesson Fees: </strong>No hidden charges—keep
            everything you earn.
          </p>

          <p className="coach-text">
            <strong>Flexible Scheduling: </strong>Teach on your terms—set your
            own availability
          </p>

          <p className="coach-text">
            <strong>Promote Your Skills: </strong>Highlight what makes you
            unique as a coach.
          </p>

          <p className="coach-call-to-action">
            Sign up for free today and start inspiring the next generation of
            surfers!
          </p>
          <Link to="/apply-to-coach">
            <button className="coach-button">
              Apply To Become A Surf Coach!
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
