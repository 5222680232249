import React, { useState, useEffect, useRef } from "react";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export default function ApplyToCoach() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cities, setCities] = useState("");
  const [experience, setExperience] = useState("");
  const [privateLessonRate, setPrivateLessonRate] = useState("");
  const [groupLessonRate, setGroupLessonRate] = useState("");
  const [state, setStateName] = useState("");
  const [profileAbout, setProfileAbout] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);

  const [successMessage, setSuccessMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warning, setWarning] = useState("");
  const formRef = useRef(null);
  const [backgroundImage, setBackgroundImage] = useState(""); // State to track background image

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const img = new Image();
    img.src = "../../public/images/new-6.jpg";
    img.onload = () => {
      setBackgroundImage(`url(${img.src})`); // Set the background image once it's loaded
    };
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    console.log("File type:", file.type, "File size:", file.size);

    if (!file) {
      setWarning("Please select a file.");
      return;
    }

    // Some devices might not provide size or type initially; handle that scenario
    const fileType = file.type || "";
    const fileSize = file.size || 0;

    // Validate file size and type
    if (fileSize > 5000000) {
      setWarning("File size should not exceed 5MB");
      return;
    }

    if (!/image\/(jpeg|jpg|pjpeg|png|gif|heic|heif)/.test(fileType)) {
      setWarning("Only image files are allowed.");
      return;
    }

    setProfilePicture(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    setSuccessMessage("");
    setIsModalOpen(false);

    const capitalizeWords = (city) => {
      return city
        .split(" ") // Split the string into an array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string
    };
    const capitalizedCity = capitalizeWords(cities);
    setCities(capitalizedCity);

    let imageUrl = null;
    if (profilePicture) {
      const { data, error } = await supabase.storage
        .from("profile-images") // Make sure "profile-pictures" is an existing bucket in Supabase
        .upload(`public/${Date.now()}-${profilePicture.name}`, profilePicture);

      if (error) {
        console.error("Error uploading image:", error);
        setWarning("Error uploading image.");
        setIsLoading(false);
        return;
      }

      // Get the public URL for the uploaded image
      const { data: urlData, error: urlError } = supabase.storage
        .from("profile-images")
        .getPublicUrl(data.path);

      if (urlError) {
        console.error("Error getting public URL:", urlError);
        setWarning("Error retrieving public URL.");
        setIsLoading(false);
        return;
      }
      imageUrl = urlData.publicUrl; // Store the public URL
    }

    const experienceValue = experience.trim();
    if (!/^\d+$/.test(experienceValue)) {
      setWarning("Please enter a number for experience.");
      return;
    } else {
      setWarning("");
    }

    const privateLessonRateValue = privateLessonRate.trim().replace("$", "");
    if (!/^\d+(\.\d{2})?$/.test(privateLessonRateValue)) {
      setWarning("Please enter a valid number for private lesson rate.");
      return;
    }

    const groupLessonRateValue = groupLessonRate.trim().replace("$", "");
    if (!/^\d+(\.\d{2})?$/.test(groupLessonRateValue)) {
      setWarning("Please enter a valid number for group lesson rate.");
      return;
    }

    if (!/^[a-zA-Z]+$/.test(firstName)) {
      setWarning("First name can only contain letters.");
      return;
    }
    if (!/^[a-zA-Z]+$/.test(lastName)) {
      setWarning("Last name can only contain letters.");
      return;
    }

    if (!/^[a-zA-Z\s]+$/.test(state)) {
      setWarning("State can only contain letters and spaces.");
      return;
    }

    setWarning("");

    const citiesArray = cities.split(",").map((city) => city.trim());

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("state", state.toUpperCase());
    formData.append("experience", experience);
    formData.append("privateLessonRate", privateLessonRateValue);
    formData.append("groupLessonRate", groupLessonRateValue);
    formData.append("profileAbout", profileAbout);
    formData.append("profilePictureLink", imageUrl);
    formData.append("cities", citiesArray.join(","));

    try {
      const response = await fetch(
        "https://findsurfinglessons.onrender.com/api/coaches",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        setSuccessMessage(
          `Thank you for applying to become a coach! Your application is under review, and we will notify you once your coach profile is live. This process typically takes up to 24 hours.`
        );
        setIsModalOpen(true);

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNumber("");
        setCities("");
        setStateName("");
        setExperience("");
        setPrivateLessonRate("");
        setGroupLessonRate("");
        setProfileAbout("");
        setProfilePicture(null);
      } else {
        console.error("Error adding coach:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSuccessMessage("");
  };

  const scrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div
      className="apply-to-coach-body"
      style={{ backgroundImage: backgroundImage }} // Apply background image
    >
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <p>Submitting... Please wait.</p>
        </div>
      )}
      <div className="apply-to-coach-header">
        <div className="apply-to-coach-text">
          <h1>Become A Surf Coach Today</h1>
          <p>
            Inspire students, set your own hours and locations, and earn 100% of
            every lessons!
          </p>
          <button onClick={scrollToForm}>Apply Now!</button>
        </div>
      </div>

      <div className="apply-to-coach-container">
        <div className="apply-to-coach-info-container">
          <div className="apply-to-coach">
            <h1>Steps To Become A Surf Coach</h1>
            <h3>1. Create a profile for free</h3>
            <h3>2. Find new surfing sdudents</h3>
            <h3>3. Teach at your own convenience</h3>
            <h3>4. Get paid directly from students</h3>
            <h3>5. Earn 100% of proceeds!</h3>
          </div>

          <div className="apply-to-coach-two">
            <h2>Why Become a Surf Coach?</h2>
            <p>
              <strong>1. Competitive Compensation:</strong> We recognize the
              value of your expertise and commitment. After your initial
              session, you’ll retain 100% of the earnings from every lesson you
              teach.
            </p>
            <p>
              <strong>2. Flexible Schedule:</strong> You can set your own
              coaching hours and choose the locations where you prefer to teach.
              This allows you to balance your coaching with other personal and
              professional commitments.
            </p>
            <p>
              <strong>3. Share Your Passion:</strong> As a surf coach, you'll
              have the chance to share your love for the sport with others.
              Whether you're helping a beginner catch their first wave or
              refining the skills of an experienced surfer, your guidance can
              inspire and motivate students to reach new heights.
            </p>
          </div>
        </div>
        <div className="form-box" ref={formRef}>
          <form onSubmit={handleSubmit}>
            <div className="apply-to-coach-form">
              <h2>Apply To Coach Form</h2>
              <h4>
                Join our community of expert surf coaches <br></br>
                and share your passion for surfing with others. <br></br>
                Fill out the form below to apply.
              </h4>
              <input
                type="text"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Years of Experience"
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Private Lesson Rate"
                value={privateLessonRate}
                onChange={(e) => setPrivateLessonRate(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="Group Lesson Rate"
                value={groupLessonRate}
                onChange={(e) => setGroupLessonRate(e.target.value)}
                className="input-field"
                required
              />
              <input
                type="text"
                placeholder="State"
                value={state}
                onChange={(e) => setStateName(e.target.value)}
                className="input-field"
                required
              />
              <textarea
                type="text"
                placeholder="Enter Cities (comma-separated) where you'd like to teach (e.g. Huntington, Newport, Laguna)"
                value={cities}
                onChange={(e) => setCities(e.target.value)}
                className="input-field profile-about"
                required
              />
              <textarea
                placeholder="Coach Profile: Share your experience, teaching philosophy, and accomplishments to showcase your expertise"
                value={profileAbout}
                onChange={(e) => setProfileAbout(e.target.value)}
                className="input-field profile-about"
                required
              />
              <label className="file-input-label">
                Upload Profile Picture
                <input
                  type="file"
                  name="profilePicture"
                  accept="image/*"
                  onChange={handleImageChange}
                  className="input-field"
                  required
                />
              </label>

              {warning && <p style={{ color: "red" }}>{warning}</p>}
              <button type="submit" className="apply-to-coach-submit-button">
                Submit
              </button>
            </div>
          </form>
          {isModalOpen && (
            <div className="modal">
              <div className="success-container">
                <h1 className="success-message">Form Submitted</h1>
                <p className="success-description">{successMessage}</p>
                <button className="close-button-success" onClick={closeModal}>
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
