import { useState, useEffect } from "react";

export default function () {
  const [contactBackgroundImage, setContactBackgroundImage] = useState(""); // State to track background image

  useEffect(() => {
    window.scrollTo(0, 0);
    const img = new Image();
    img.src = "../../public/images/surf-2.jpg";
    img.onload = () => {
      setContactBackgroundImage(`url(${img.src})`); // Set the background image once it's loaded
    };
  }, []);
  return (
    <div className="contact-page"
    style={{ contactBackgroundImage: contactBackgroundImage }} // Apply background image
    >
      <div className="contact-container">
        <h1>Contact Us</h1>
        <div className="text-container">
          <p>
            We’d love to hear from you! Whether you have a question about our
            services, need assistance with booking, or just want to learn more
            about surfing, we're here to help.
          </p>
          <p>
            For general inquiries, please email us at{" "}
            <a href="mailto:info@surfcoachapp.com" className="email">
              getsurfinglessons@gmail.com
            </a>
            . We'll get back to you within 24 hours.
          </p>
        </div>
      </div>
    </div>
  );
}
