import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_LOCATIONS } from "./data/locations";

const StartYourJourney = () => {
  const [findCity, setFindCity] = useState("");
  const [findState, setFindState] = useState("");
  const [allCities, setAllCities] = useState(DEFAULT_LOCATIONS.cities);
  const [allStates, setAllStates] = useState(DEFAULT_LOCATIONS.states);
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!findCity || !findState) {
      alert("Please select both a city and state");
      return;
    }

    navigate(`/start-your-journey/${findCity}/${findState}`, {
      state: {
        city: findCity.trim(),
        state: findState.trim(),
        scrollToFirstCoach: true,
      },
    });
  };

  return (
    <div className="start-your-journey">
      <div className="start-your-journey-header">
        <div className="start-your-journey-text">
          <h1 className="start-your-journey-header-title">Find Surf Coaches</h1>
          <p>Search for a surf coach in your area!</p>
        </div>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="select-container">
              <select
                id="city-dropdown"
                value={findCity}
                onChange={(e) => setFindCity(e.target.value)}
                className="dropdown-journey"
              >
                <option value="">Select A City</option>
                {allCities.map((city, index) => (
                  <option key={index} value={city}>
                    {city}
                  </option>
                ))}
              </select>

              <select
                id="state-dropdown"
                value={findState}
                onChange={(e) => setFindState(e.target.value)}
                className="dropdown-journey"
              >
                <option value="">Select A State</option>
                {allStates.map((state, index) => (
                  <option key={index} value={state}>
                    {state}
                  </option>
                ))}
              </select>
            </div>

            <button type="submit" className="submit-btn-jour">
              Find Coaches
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default StartYourJourney;
