export const DEFAULT_LOCATIONS = {
  cities: [
    "Huntington",
    "Newport",
    "Laguna",
    "San Clemente",
    "San Francisco",
    "Dana Point",
    "Oceanside",
    "San Diego",
    "Santa Cruz",
    "La Jolla",
    "Pacific Beach",
    "Mission Beach",
    "Waikiki",
    "North Shore",
    "Cocoa Beach",
    "Jacksonville Beach",
    "Miami Beach"
  ],
  states: [
    "CA",
    "HI",
    "FL"
  ]
}; 