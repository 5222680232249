import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom"; // Import from react-router-dom
import { useLocation, Link } from "react-router-dom";
import axios from "axios";
import { DEFAULT_LOCATIONS } from "./data/locations";

const SurfCoaches = () => {
  const { findCity, findState } = useParams(); // Retrieves from the URL
  const navigate = useNavigate();
  const [filteredCoaches, setFilteredCoaches] = useState([]);
  const [findCityTwo, setFindCityTwo] = useState(findCity || "");
  const [findStateTwo, setFindStateTwo] = useState(findState || "");
  const [numberOfCoaches, setNumberOfCoaches] = useState("");
  const [filtering, setFiltering] = useState("");
  const [loading, setLoading] = useState(false);

  const [allCitiesTwo] = useState(DEFAULT_LOCATIONS.cities);
  const [allStatesTwo] = useState(DEFAULT_LOCATIONS.states);

  const firstCoachRef = useRef(null);

  const filterAndSortCoaches = (findCityTwo, findStateTwo, coaches) => {
    const filtered = coaches.filter((coach) => {
      const coachCities = coach.cities?.map((city) => city.toLowerCase()) || [];
      return (
        coach.state.toLowerCase() === findStateTwo.toLowerCase() &&
        coachCities.includes(findCityTwo.toLowerCase())
      );
    });

    const sortedItems = filtered.sort(
      (a, b) => a.privatelessonrate - b.privatelessonrate
    );

    setFilteredCoaches(sortedItems);
    setNumberOfCoaches(
      sortedItems.length === 0
        ? "No Coaches Found. Coming Soon!"
        : sortedItems.length === 1
        ? "1 Coach Found"
        : `${sortedItems.length} Coaches Found`
    );
  };

  // Fetch coaches when component mounts or when a search is submitted
  useEffect(() => {
    const fetchCoaches = async () => {
      setLoading(true);
      try {
        const coachesResponse = await axios.get(
          "https://findsurfinglessons.onrender.com/api/coaches",
          { withCredentials: true }
        );

        if (findCityTwo || findStateTwo) {
          filterAndSortCoaches(findCityTwo, findStateTwo, coachesResponse.data);
        }
      } catch (error) {
        console.error("Error fetching coaches data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCoaches();
  }, [findCityTwo, findStateTwo]); // Only trigger on location change or search submission

  useEffect(() => {
    if (findCityTwo && findStateTwo) {
      navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
    }
  }, [findCityTwo, findStateTwo, navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFindCityTwo(findCityTwo);
    setFindStateTwo(findStateTwo);
    navigate(`/start-your-journey/${findCityTwo}/${findStateTwo}`);
  };

  const handleFiltering = (event) => {
    const selected_option = event.target.value;
    setFiltering(event.target.value);
    if (selected_option === "Private Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.privatelessonrate - b.privatelessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Group Lesson Rate") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => a.grouplessonrate - b.grouplessonrate
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Experience") {
      const sortedItems = [...filteredCoaches].sort(
        (a, b) => b.experience - a.experience
      );
      setFilteredCoaches(sortedItems);
    } else if (selected_option === "Alphabetical") {
      const sortedItems = [...filteredCoaches].sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      );
      setFilteredCoaches(sortedItems);
    }
  };

  return (
    <div className="body">
      <div className="start-your-journey-body-top">
        <h1>Search for a Coach Near Your Area</h1>
        <h2 className="surf-coach-title">
          Surf Coaches in {findCityTwo}, {findStateTwo.toUpperCase()}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="select-container">
            <select
              id="city-dropdown"
              value={findCityTwo}
              onChange={(e) => setFindCityTwo(e.target.value)}
              className="dropdown"
            >
              <option value="">
                {allCitiesTwo.length == 0 ? "Loading..." : "Select A City"}
              </option>
              {allCitiesTwo.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>

            <select
              id="state-dropdown"
              value={findStateTwo}
              onChange={(e) => setFindStateTwo(e.target.value)}
              className="dropdown"
            >
              <option value="">
                {allStatesTwo.length == 0 ? "Loading..." : "Select A State"}
              </option>
              {allStatesTwo.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
        </form>
      </div>
      <div className="parent-container-coaches">
        <div className="number-of-coaches">
          <h3 className="location-heading">
            {findCityTwo}, {findStateTwo.toUpperCase()}
          </h3>
          <div className="filtering-coaches">
            <h3>{numberOfCoaches}</h3>
          </div>
          <select
            value={filtering}
            onChange={handleFiltering}
            className="select-options"
          >
            <option value="" disabled>
              Sort By
            </option>
            <option value="Private Lesson Rate">Private Lesson Rate</option>
            <option value="Group Lesson Rate">Group Lesson Rate</option>
            <option value="Experience">Years of Experience</option>
            <option value="Alphabetical">Alphabetical Order</option>
          </select>
        </div>
      </div>

      <div className="start-your-journey-body">
        <div className="start-your-journey-container">
          {loading ? (
            <p className="loading-message">Loading coaches...</p>
          ) : filteredCoaches.length > 0 ? (
            filteredCoaches.map((coach, index) => (
              <div
                className="coach-card"
                key={coach.id}
                ref={index === 0 ? firstCoachRef : null}
              >
                {coach.profile_picture && (
                  <div className="profile-picture-container">
                    <img
                      src={`${coach.profile_picture}`}
                      alt={`${coach.first_name} ${coach.lastName} profile picture`}
                      className="profile-picture-journey"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "../../public/images/surf-2.jpg"; // Set a default image path
                      }}
                    />
                  </div>
                )}
                <div className="coach-card-text">
                  <h2 className="coach-name">Coach {coach.first_name}</h2>
                  <h3>
                    {findCityTwo}, {findStateTwo.toUpperCase()}
                  </h3>
                  <h4>
                    {coach.experience}{" "}
                    {coach.experience === 1 ? "year" : "years"} of experience
                  </h4>
                  <p>
                    <strong>
                      Private Lesson Rate: ${coach.privatelessonrate}/hr
                    </strong>
                  </p>
                  <strong>
                    <p>Group Lesson Rate: ${coach.grouplessonrate}/hr</p>
                  </strong>
                </div>
                <Link
                  to={`/start-your-journey/${findCityTwo}/${findStateTwo}/${coach.id}`} // Correct Link usage
                >
                  <button className="profile-button">
                    View {coach.first_name}'s Profile
                  </button>
                </Link>
              </div>
            ))
          ) : (
            findCityTwo && findStateTwo && <p></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SurfCoaches;
