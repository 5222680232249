import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminDashboard = () => {
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    const fetchPendingCoaches = async () => {
      try {
        const response = await axios.get(
          "https://findsurfinglessons.onrender.com/api/pendingCoaches"
        );
        setCoaches(response.data);
      } catch (error) {
        console.error("Error fetching pending coaches:", error);
      }
    };
    fetchPendingCoaches();
  }, []);

  const handleApprove = async (coachId) => {
    try {
      await axios.put(
        `https://findsurfinglessons.onrender.com/api/coaches/approve/${coachId}`
      );
      setCoaches((prev) => prev.filter((coach) => coach.id !== coachId));
    } catch (error) {
      console.error("Error approving coach:", error);
    }
  };

  const handleDisapprove = async (coachId) => {
    try {
      await axios.put(
        `https://findsurfinglessons.onrender.com/api/coaches/disapprove/${coachId}`
      );
      setCoaches((prev) => prev.filter((coach) => coach.id !== coachId));
    } catch (error) {
      console.error("Error approving coach:", error);
    }
  };

  return (
    <div>
      <h1 className="title">Admin Dashboard - Pending Coaches</h1>
      <ul>
        <div className="start-your-journey-body">
          <div className="start-your-journey-container">
            {coaches.map((coach) => {
              return (
                <div className="admin-coach-card" key={coach.id}>
                  <div className="profile-picture-journy">
                    <img
                      src={`${coach.profile_picture}`}
                      alt={`${coach.first_name} ${coach.last_name} profile picture`}
                      className="profile-picture-journey"
                    />
                  </div>
                  <div className="admin-coach-card-text">
                    <p>
                      Coach {coach.first_name} {coach.last_name}
                    </p>
                    <p>Experience: {coach.experience} year(s)</p>
                    <p>Private rate: ${coach.privatelessonrate}</p>
                    <p>Group Rate: ${coach.grouplessonrate}</p>
                    <p>
                      Location: {coach.state}, {coach.cities}
                    </p>
                    <p>Email: {coach.email}</p>
                    <p>Number: {coach.phonenumber}</p>
                    <p className="bio-text">Bio: {coach.profile_about}</p>
                  </div>
                  <button onClick={() => handleApprove(coach.id)}>
                    Approve
                  </button>
                  <button
                    onClick={() => handleDisapprove(coach.id)}
                    className="disapprove-btn"
                  >
                    Disapprove
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </ul>
    </div>
  );
};

export default AdminDashboard;
