import React from "react";
import { Outlet } from "react-router-dom"; // Outlet is used for nested routes
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="layout">
      <main className="content">
        <Outlet /> {/* This will render the child routes */}
      </main>
      <footer className="footer">
        <h2>Get Surfing Lessons</h2>
        <div className="footer-content">
          <div className="footer-section">
            <h3>Contact Us</h3>
            <p>
              Email:{" "}
              <a href="mailto:info@surfcoaching.com">
                getsurfinglessons@gmail.com
              </a>
            </p>
          </div>
          <div className="footer-section">
            <h3>Quick Links</h3>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/start-your-journey">Start Your Journey</Link>
              </li>
              <li>
                <Link to="/apply-to-coach">Apply To Coach</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/Contact">Contact Page</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; 2024 GetSurfingLessons.com All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
