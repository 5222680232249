import React, { useEffect, useState } from "react";
import {useParams } from "react-router-dom";
import ContactModal from "./ContactModal";
import axios from "axios";
import { FaStar } from "react-icons/fa"; // Make sure to install react-icons

const Profile = () => {
  const { id, findCity } = useParams();
  const [contactForm, setContactForm] = useState(false);
  const [coach, setCoach] = useState([]);
  const [error, setError] = useState(null);
  const [finalCity, setFinalCity] = useState("");
  const [review, setReview] = useState("");
  const [reviewName, setReviewName] = useState("");
  const [reviewData, setReviewData] = useState([]);
  const [isReviewModalOpen, setReviewModalOpen] = useState(false);
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [hasReviewed, setHasReviewed] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`https://findsurfinglessons.onrender.com/api/coaches/${id}`) // Include the id in the request URL
      .then((response) => {
        setCoach(response.data); // Set the coach data
        setError(null); // Clear any previous errors
      })
      .catch((error) => {
        setError("There was an error fetching the coach data.");
        console.error("There was an error fetching the coach data:", error);
      });

    axios
      .get(`https://findsurfinglessons.onrender.com/api/reviews/${id}`)
      .then((response) => {
        setReviewData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the reviews:", error);
      });

    const reviewedCoaches =
      JSON.parse(localStorage.getItem("reviewedCoaches")) || [];
    if (reviewedCoaches.includes(id)) {
      setHasReviewed(true);
    }

    const capitalizeWords = (city) => {
      return city
        .split(" ") // Split the string into an array of words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
        .join(" "); // Join the words back into a single string
    };
    setReview("");
    const capitalizedCity = capitalizeWords(findCity);
    setFinalCity(capitalizedCity);
  }, [id]);

  if (!coach) return <div>Loading...</div>;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formatDate = (date) => {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return date.toLocaleDateString(undefined, options);
    };
    const reviewData = {
      reviewName,
      review,
      rating,
      timestamp: formatDate(new Date()),
    };

    console.log("Submitting review data: ", reviewData); // Check what is being submitted

    try {
      const response = await fetch(`https://findsurfinglessons.onrender.com/api/reviews/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reviewData),
      });

      if (response.ok) {
        const data = await response.json();
        setReviewName("");
        setReviewText("");
        setRating(0);
        setReview("");
        // Optionally fetch the reviews again to include the new review in the display
        const updatedReviews = await axios.get(
          `https://findsurfinglessons.onrender.com/api/reviews/${id}`
        );
        setReviewData(updatedReviews.data);

        const reviewedCoaches =
          JSON.parse(localStorage.getItem("reviewedCoaches")) || [];
        reviewedCoaches.push(id);
        localStorage.setItem(
          "reviewedCoaches",
          JSON.stringify(reviewedCoaches)
        );
        setHasReviewed(true);
      } else {
        console.error("Error adding review", response.statusText);
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    setReviewText("");
    handleCloseModal();
  };

  const handleOpenModal = () => {
    if (!hasReviewed) {
      setReviewModalOpen(true);
    } else {
      alert("You have already submitted a review for this coach.");
    }
  };

  const handleCloseModal = () => {
    setReviewModalOpen(false);
  };

  const handleRatingClick = (value) => {
    setRating(value); // Set the rating based on clicked star
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue == "Lowest Rated") {
      const sortedItems = [...reviewData].sort((a, b) => a.rating - b.rating);
      setReviewData(sortedItems);
    } else if (selectedValue == "Highest Rated") {
      const sortedItems = [...reviewData].sort((a, b) => b.rating - a.rating);
      setReviewData(sortedItems);
    } else if (selectedValue == "Most Recent") {
      const sortedItems = [...reviewData].sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
      );
      setReviewData(sortedItems);
    }
  };

  const renderStars = (rating) => {
    return (
      <div className="review-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <FaStar
            key={star}
            className={star <= rating ? "filled" : "empty"}
            size={24} // Adjust size as needed
            color={star <= rating ? "#FFD700" : "#ccc"} // Gold for filled, gray for empty
          />
        ))}
      </div>
    );
  };

  return (
    <div className="profile-container">
      <div className="profile">
        <div className="profile-left">
          <div className="profile-coach-card">
            <h1>Profile of Coach {coach.first_name}</h1>
            <img
              src={`${coach.profile_picture}`}
              alt={`${coach.first_name} ${coach.last_name} profile picture image`}
              className="profile-picture"
            />
            <div className="profile-coach-card-text">
              <h2>Coach {coach.first_name}</h2>
              <h3>
                {finalCity}, {coach.state}
              </h3>
              <h4>Experience: {coach.experience} years</h4>
              <p>
                <strong>
                  Private Lesson Rate: ${coach.privatelessonrate}/hr
                </strong>
              </p>
              <p>
                <strong>Group Lesson Rate: ${coach.grouplessonrate}/hr</strong>
              </p>
            </div>
            <button onClick={() => setContactForm(true)} className="contact-me">
              Contact Me!
            </button>
          </div>

          <ContactModal
            isOpen={contactForm}
            onClose={() => setContactForm(false)}
            coachfirstName={coach.first_name}
            coachlastName={coach.last_name}
            coachCity={findCity}
            coachEmail={coach.email}
            coachPhoneNumber={coach.phonenumber}
          />

          <div className="past-review-system">
            <div className="reviews-container">
              <h3 className="coach-name-reviews">
                Coach {coach.first_name} Reviews
              </h3>
              <div className="header-section">
                <button className="review-button" onClick={handleOpenModal}>
                  Write A Review
                </button>
                <select
                  className="sort-select"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="Most Recent">Most Recent</option>
                  <option value="Highest Rated">Highest Rated</option>
                  <option value="Lowest Rated">Lowest Rated</option>
                </select>
              </div>
            </div>

            <div className="display-reviews">
              {reviewData.length > 0 ? (
                reviewData.map((review) => (
                  <div key={review.id} className="display-each-review">
                    <div className="review-name">{review.first_name}</div>
                    <div className="star-date">
                      <div className="review-rating">
                        {renderStars(review.rating)}
                      </div>
                      <div className="review-timestamp">
                        {new Date(review.timestamp).toLocaleDateString()}
                      </div>
                    </div>
                    <div className="review-text">{review.review}</div>
                  </div>
                ))
              ) : (
                <h5 className="no-reviews">No reviews available yet</h5>
              )}
            </div>
          </div>

          {isReviewModalOpen && (
            <div className="modal-overlay">
              <div className="modal-content">
                <h2 className="about-title">Leave a Review</h2>
                <button
                  type="button"
                  onClick={handleCloseModal}
                  className="close-button"
                  aria-label="Close"
                >
                  &times; {/* "X" character */}
                </button>
                <form onSubmit={handleSubmit} className="review-form">
                  <input
                    type="text"
                    placeholder="Your Name"
                    value={reviewName}
                    onChange={(e) => setReviewName(e.target.value)}
                    required
                    className="form-input"
                  />
                  <div className="rating-container">
                    <label className="rating-label">Rating</label>
                    <div className="rating-input">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <FaStar
                          key={star}
                          className={`star ${
                            star <= rating ? "filled" : "empty"
                          }`}
                          onClick={() => handleRatingClick(star)}
                          size={24} // Adjust size as needed
                          color={star <= rating ? "#FFD700" : "#ccc"} // Gold for filled, gray for empty
                        />
                      ))}
                    </div>
                  </div>
                  <textarea
                    placeholder="Write Your Review here ..."
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                    className="form-textarea"
                  />
                  <div className="button-group">
                    <button type="submit" className="submit-button">
                      Submit Review
                    </button>
                    {/* Close button replaced with "X" character */}
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="close-button"
                      aria-label="Close"
                    >
                      &times;
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>

        <div className="profile-experience">
          <h2 className="about-title">
            <strong>About {coach.first_name}</strong>
          </h2>
          <div className="about-content">
            <p className="contact-info">{coach.profile_about}</p>
            <p className="contact-info">
              As a dedicated surf coach based in {findCity}, I’m passionate
              about sharing my love for surfing with others. Whether you're just
              getting started or you're an experienced surfer looking to refine
              your skills, I offer personalized instruction for all ages and
              skill levels.
            </p>
            <p className="contact-info">
              For beginners, my focus is on helping you build confidence in the
              water, ensuring you feel safe and comfortable as you learn the
              fundamentals of surfing. We’ll work together on mastering
              techniques, understanding ocean conditions, and developing the
              skills you need to progress.
            </p>
            <p className="contact-info">
              For more advanced surfers, I’m here to help you take your
              abilities to the next level. Whether you’re looking to improve
              your maneuvers, increase your wave count, or understand how to
              read the ocean better, my goal is to tailor the lessons to meet
              your specific needs. I’m eager to help you improve your surfing,
              whether you're starting from scratch or aiming to enhance your
              existing skills.
            </p>
            <p className="contact-info">
              Please feel free to reach out to me through my 
              profile. I’ll get back to you promptly to schedule your first
              lesson and answer any questions you might have. Let’s catch some
              waves together and make your surfing journey an exciting and
              rewarding experience!
            </p>
            <p className="contact-info">
              For group lessons, please arrange your group in advance before
              reaching out to me for scheduling.
            </p>
            <button onClick={() => setContactForm(true)} className="contact-me">
              Contact Me!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
