import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1100);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1100);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <div className="header">
      {isMobile && (
        <>
          <button className="hamburger" onClick={toggleMenu}>
            &#9776;
          </button>
          <div className={`menu-overlay ${isOpen ? "open" : ""}`} onClick={toggleMenu}></div>
        </>
      )}
      {isMobile && isOpen && (
        <div className="header-tags open">
          <button className="close-menu" onClick={toggleMenu}>
            &times;
          </button>
          <Link to="/">
            <h3>Home</h3>
          </Link>
          <Link to="/start-your-journey">
            <h3>Start Your Journey</h3>
          </Link>
          <Link to="/apply-to-coach">
            <h3>Apply To Coach</h3>
          </Link>
          <Link to="/about">
            <h3>About</h3>
          </Link>
          <Link to="/contact">
            <h3>Contact</h3>
          </Link>
        </div>
      )}
      {!isMobile && (
        <Link to="/">
          <h1>Get Surfing Lessons</h1>
        </Link>
      )}
      {!isMobile && (
        <div className="header-tags">
          <Link to="/start-your-journey">
            <h3>Start Your Journey</h3>
          </Link>
          <Link to="/apply-to-coach">
            <h3>Apply To Coach</h3>
          </Link>
          <Link to="/about">
            <h3>About</h3>
          </Link>
          <Link to="/contact">
            <h3>Contact</h3>
          </Link>
        </div>
      )}
    </div>
  );
}
