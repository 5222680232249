import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";

export default function () {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="about-page">
      <section className="section red-container">
        <img src="/images/surf-4.jpg" alt="People Surfing" className="surfing-image" />
        <div className="text-content red-text">
          <h1>About Get Surfing Lessons</h1>
          <p>
            At Get Surfing Lessons, we’re passionate about connecting aspiring
            surfers with experienced coaches who are just as passionate about
            the ocean as you are. Whether you’re looking to catch your first
            wave or perfect your technique, we’re here to make your surfing
            journey unforgettable.
          </p>
        </div>
      </section>

      <section className="section blue-container">
        <img src="/images/surf-5.jpg" alt="Riding A Wave Image" className="surfing-image" />
        <div className="text-content blue-text">
          <h1>Our Mission</h1>
          <p>
            Our mission is to make surfing accessible to everyone, regardless of
            experience level. We believe in fostering a community where surfers
            of all kinds can come together, learn, and grow. We’re dedicated to
            helping you find the perfect coach to guide you on your surfing
            journey, from the very first lesson to mastering advanced
            techniques.
          </p>
        </div>
      </section>

      <section className="section red-container">
        <img src="/images/new-1.jpg" alt="Having Fun Surfing" className="surfing-image" />
        <div className="text-content red-text">
          <h1>Why Choose Get Surfing Lessons?</h1>
          <p>
            <strong>Tailored Learning:</strong> We match you with coaches whose
            teaching style aligns with your learning preferences, ensuring
            personalized and effective lessons.<br></br>
            <strong> Local Expertise: </strong> Our coaches are local experts
            who know the best spots to surf, depending on the weather and your
            skill level.
            <br></br>
            <strong>Flexibility: </strong> We offer flexible scheduling to fit
            your busy lifestyle, making it easier for you to find the perfect
            time to surf.
            <br></br>
            <strong>Community Focused: </strong>We are more than just a
            platform; we are a community dedicated to spreading the joy of
            surfing.
          </p>
        </div>
      </section>

      <section className="section blue-container">
        <img src="/images/img4.jpg" alt="Surfing Lesson Image" className="surfing-image" />
        <div className="text-content blue-text">
          <h1>Ready To Ride The Waves?</h1>
          <p>
            Whether you’re a beginner looking to get started or a seasoned
            surfer seeking to improve your skills, we’re here to help. Start
            your journey with Get Surfing Lessons today!
          </p>
          <div className="button-group">
            <Link to="/start-your-journey">
              <button className="btn">Meet Coaches</button>
            </Link>
            <Link to="/apply-to-coach">
              <button className="btn">Apply To Become A Surf Coach</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="section red-container">
        <img src="/images/surf-3.jpg" alt="Surfing Ride Image" className="surfing-image" />
        <div className="text-content red-text">
          <h1>Our Journey</h1>
          <p>
            Get Surfing Lessons was born out of a love for the ocean and a
            desire to share that passion with others. We started with a small
            group of local surfers who wanted to teach others and have since
            grown into a thriving community of surfers and coaches. Our journey
            is all about spreading the stoke of surfing to as many people as
            possible.
          </p>
        </div>
      </section>
    </div>
  );
}
